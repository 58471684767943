import type { ReportingSchema } from "@milio/lib/data/report/type";

import * as R from "ramda";
import { CountryData } from "@milio/lib/util/country/index";
import * as currencyCodes from "currency-codes/data";

import {
  toCurrencyEnumerations,
  toCountryEnumerations,
} from "../employee/util";
import {
  AggregationType,
  Combinator,
  SortDirection,
  DataType,
  ReportType,
  Operator,
  GraphType,
} from "@milio/lib/data/report/constant";
import {
  DataTypeAggregationTypeMap,
  DataTypeTickFormatMap,
  DataTypeValueFormatMap,
  DataTypeOperatorMap,
  EnumerationDataTypeOperatorMap,
} from "@milio/lib/data/report/operator-map";

export const Schema: ReportingSchema<any> = {
  id: ReportType.Plan,
  title: "Plan",
  description: "A compensation ",
  sort: [
    {
      direction: SortDirection.Ascend,
      field: "title",
    },
  ],
  order: [
    "title",
    "remote_id",
    "zone.country_details.nicename",
    "zone.description",
  ],
  combinators: [Combinator.And, Combinator.Or],
  defaultField: "metrics.employee_count",
  defaultOperator: Operator.GreaterThan,
  maxFilters: 5,
  maxGroups: 5,
  properties: {
    title: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      defaultValue: "",
      defaultOperator: Operator.Contains,
      description: "Job Title",
      example: "HR Manager",
      key: "title",
      title: "Job Title",
      type: DataType.String,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.String, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.String, DataTypeValueFormatMap),
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    remote_id: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      defaultOperator: Operator.Contains,
      description: "Plan ID from upstream system.",
      example: "1234",
      key: "remote_id",
      title: "Plan ID",
      type: DataType.String,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.String, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.String, DataTypeValueFormatMap),
    },
    zone: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      description: "Zone related to ",
      example: "Zone 1",
      key: "zone.description",
      title: "Zone",
      type: DataType.String,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.String, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.String, DataTypeValueFormatMap),
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    country: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.String, EnumerationDataTypeOperatorMap),
      enumeration: toCountryEnumerations(CountryData),
      description: "Country related to ",
      example: "Spain",
      key: "zone.country_details.nicename",
      title: "Country",
      type: DataType.String,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.String, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.String, DataTypeValueFormatMap),
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    pay_band_min: {
      aggregations: R.prop(DataType.Currency, DataTypeAggregationTypeMap),
      dependencies: ["currency"],
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      description: "Pay band minimum",
      example: "$123,456",
      key: "pay_band_min",
      title: "Pay Band Min",
      type: DataType.Currency,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    pay_band_midpoint: {
      aggregations: R.prop(DataType.Currency, DataTypeAggregationTypeMap),
      dependencies: ["currency"],
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      description: "Pay band midpoint",
      example: "$123,456",
      key: "pay_band_midpoint",
      title: "Pay Band Midpoint",
      type: DataType.Currency,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    pay_band_max: {
      aggregations: R.prop(DataType.Currency, DataTypeAggregationTypeMap),
      dependencies: ["currency"],
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      description: "Pay band maximum",
      example: "$123,456",
      key: "pay_band_max",
      title: "Pay Band Max",
      type: DataType.Currency,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    external_benchmark_25: {
      aggregations: [AggregationType.Count, AggregationType.DistinctCount],
      dependencies: ["currency"],
      description:
        "25th percentile of the plan's most recent external benchmark.",
      example: "125,000",
      key: "benchmark.pct_25",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "External Benchmark (25th pctl)",
      type: DataType.Currency,
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
      queryable: false,
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    external_benchmark_50: {
      aggregations: [AggregationType.Count, AggregationType.DistinctCount],
      dependencies: ["currency"],
      description:
        "50th percentile of the plan's most recent external benchmark.",
      example: "125,000",
      key: "benchmark.pct_50",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "External Benchmark (50th pctl)",
      type: DataType.Currency,
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
      queryable: false,
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    external_benchmark_75: {
      aggregations: [AggregationType.Count, AggregationType.DistinctCount],
      dependencies: ["currency"],
      description:
        "75th percentile of the plan's most recent external benchmark.",
      example: "125,000",
      key: "benchmark.pct_75",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "External Benchmark (75th pctl)",
      type: DataType.Currency,
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
      queryable: false,
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },

    employee_count: {
      aggregations: R.prop(DataType.NaturalNumber, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.NaturalNumber, DataTypeOperatorMap),
      description: "Number of employees in this peer group.",
      defaultValue: 0,
      example: "8",
      key: "metrics.employee_count",
      title: "Employee Count",
      type: DataType.NaturalNumber,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.NaturalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.NaturalNumber, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    above_band_count: {
      aggregations: R.prop(DataType.NaturalNumber, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.NaturalNumber, DataTypeOperatorMap),
      description:
        "Number of employees in this peer group that are above band.",
      example: "8",
      key: "metrics.above_band_count",
      title: "Above Band",
      type: DataType.NaturalNumber,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.NaturalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.NaturalNumber, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    below_band_count: {
      aggregations: R.prop(DataType.NaturalNumber, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.NaturalNumber, DataTypeOperatorMap),
      description:
        "Number of employees in this peer group that are below band.",
      example: "8",
      key: "metrics.below_band_count",
      title: "Below Band",
      type: DataType.NaturalNumber,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.NaturalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.NaturalNumber, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    in_band_count: {
      aggregations: R.prop(DataType.NaturalNumber, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.NaturalNumber, DataTypeOperatorMap),
      description: "Number of employees in this peer group that are in band.",
      example: "8",
      key: "metrics.in_band_count",
      title: "In Band",
      type: DataType.NaturalNumber,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.NaturalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.NaturalNumber, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    job_family: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      description: "Job family this plan belongs to.",
      example: "Accounting",
      key: "job_family.name",
      title: "Job Family",
      type: DataType.String,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.String, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.String, DataTypeValueFormatMap),
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.SegmentedTable,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    job_level: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      description: "Job level this plan belongs to.",
      example: "IC1 - Junior",
      key: "job_level_label",
      title: "Job Level",
      type: DataType.String,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.String, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.String, DataTypeValueFormatMap),
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.SegmentedTable,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    has_payband: {
      aggregations: R.prop(DataType.Boolean, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.Boolean, DataTypeOperatorMap),
      description: "Whether or not this plan/zone has a payband.",
      example: "true",
      key: "has_payband",
      title: "Has Payband",
      type: DataType.Boolean,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.Boolean, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Boolean, DataTypeValueFormatMap),
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    has_benchmark: {
      aggregations: R.prop(DataType.Boolean, DataTypeAggregationTypeMap),
      operators: R.prop(DataType.Boolean, DataTypeOperatorMap),
      description: "Whether or not this plan/zone has an external benchmark.",
      example: "true",
      key: "has_benchmark",
      title: "Has Benchmark",
      type: DataType.Boolean,
      selectable: true,
      queryable: true,
      hidden: false,
      tickFormat: R.prop(DataType.Boolean, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Boolean, DataTypeValueFormatMap),
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    currency: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      defaultValue: "USD",
      description: "Currency of the ",
      enumeration: toCurrencyEnumerations(currencyCodes),
      example: "JPY",
      key: "currency",
      operators: R.prop(DataType.String, EnumerationDataTypeOperatorMap),
      title: "Currency",
      type: DataType.String,
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
  },
};
