import * as R from "ramda";
import { CountryData } from "@milio/lib/util/country";
import * as currencyCodes from "currency-codes/data";

import { ReportingSchema } from "@milio/lib/data/report/type";
import { BenchmarkGroup } from "@milio/lib/data/benchmark/type";
import {
  DataTypeAggregationTypeMap,
  DataTypeOperatorMap,
  DataTypeTickFormatMap,
  DataTypeValueFormatMap,
  EnumerationDataTypeOperatorMap,
} from "@milio/lib/data/report/operator-map";
import {
  AggregationType,
  DataType,
  FormatFunction,
  GraphType,
  ReportType,
  SortDirection,
  Operator,
  Combinator,
} from "@milio/lib/data/report/constant";
import { EmployeeStatus, Gender } from "@milio/lib/data/employee/constant";
import { ColumnWidth } from "../constant";
import { Filter } from "./filter";
import { toCurrencyEnumerations, toCountryEnumerations } from "./util";
import { HydratedEmployee } from "@api/milio";

export const Schema: ReportingSchema<HydratedEmployee> = {
  id: ReportType.Employee,
  title: "Employee",
  description: "An employee.",
  sort: [
    {
      direction: SortDirection.Ascend,
      field: "name",
    },
  ],
  order: [
    "name",
    "effective_from",
    "remote_id",
    "metrics.direct_reports",
    "gender",
    "age",
    "plan.title",
    "plan.job_family.name",
    "plan.job_level.label",
    "zone.description",
    "status",
    "exemption_status",
    "pay_rate",
    "pay",
    "pay_base",
    "pay_base_hourly",
    "plan.pay_band_min",
    "plan.pay_band_midpoint",
    "plan.pay_band_max",
    "comparatio",
    "currency",
    "metrics.tenure",
    "metrics.role_tenure",
    "join_date",
    "termination_date",
    "manager.name",
    "department",
    "department_id",
    "metrics.last_promotion",
    "metrics.last_promotion_months",
    "metrics.last_title_change",
    "metrics.last_title_change_months",
    "metrics.internal_benchmark",
    "metrics.internal_benchmark_percentile",
    "metrics.internal_benchmark_peer_count",
    "payband_status",
    "location",
    "zone.country_details.nicename",
    "performance",
    "effective_to",
    "benchmark.pct_25",
    "benchmark.pct_50",
    "benchmark.pct_75",
    "external_benchmark_group",
    "external_benchmark_percentile",
    "plan.remote_id",
  ],
  combinators: [Combinator.And, Combinator.Or],
  defaultField: "status",
  defaultSelectedFields: ["name", "pay_base"],
  maxFilters: 5,
  maxGroups: 5,
  properties: {
    age: {
      aggregations: [
        AggregationType.Average,
        AggregationType.Count,
        AggregationType.DistinctCount,
        AggregationType.Max,
        AggregationType.Min,
        AggregationType.Median,
      ],
      description: "Employee age in years.",
      example: "10",
      key: "age",
      operators: R.prop(DataType.NaturalNumber, DataTypeOperatorMap),
      title: "Age",
      type: DataType.NaturalNumber,
      tickFormat: R.prop(DataType.NaturalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.NaturalNumber, DataTypeValueFormatMap),
      display: { width: ColumnWidth.Compact },
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    pay: {
      aggregations: [AggregationType.Count, AggregationType.DistinctCount],
      dependencies: ["currency"],
      description: "The employee's base compensation in their local currency.",
      example: "125,000",
      key: "pay",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "Base Comp",
      type: DataType.Currency,
      display: { width: ColumnWidth.Standard },
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
    },
    pay_hourly: {
      aggregations: [AggregationType.Count, AggregationType.DistinctCount],
      dependencies: ["currency"],
      description:
        "The employee's base compensation (hourly) in their local currency.",
      example: "10.5",
      key: "pay_hourly",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "Base Comp (Hourly)",
      type: DataType.Currency,
      display: { width: ColumnWidth.Standard },
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
    },
    payband_status: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "The employee's payband status.",
      example: "In Band",
      key: "payband_status",
      displayLabels: true,
      enumeration: [
        {
          value: "In Band",
          label: "In Band",
        },
        {
          value: "Above Band",
          label: "Above Band",
        },
        {
          value: "Below Band",
          label: "Below Band",
        },
      ],
      operators: R.prop(DataType.String, EnumerationDataTypeOperatorMap),
      title: "Payband Status",
      type: DataType.String,
      display: { width: ColumnWidth.Medium },
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    pay_base_hourly: {
      aggregations: R.prop(DataType.RationalNumber, DataTypeAggregationTypeMap),
      description:
        "The employee's hourly base compensation in the base currency, for non-exempt employees.",
      example: "12.34",
      key: "pay_base_hourly",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "Base Comp (Hourly, Base)",
      type: DataType.Currency,
      display: { width: ColumnWidth.Small },
      tickFormat: FormatFunction.CurrencyDecimal,
      valueFormat: FormatFunction.CurrencyDecimal,
    },
    pay_base: {
      aggregations: R.prop(DataType.RationalNumber, DataTypeAggregationTypeMap),
      description: "The employee's base compensation in the base currency.",
      example: "125,000",
      key: "pay_base",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "Base Comp",
      type: DataType.Currency,
      display: { width: ColumnWidth.Medium },
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    comparatio: {
      aggregations: [
        AggregationType.Average,
        AggregationType.Count,
        AggregationType.DistinctCount,
        AggregationType.Max,
        AggregationType.Min,
        AggregationType.Median,
      ],
      description:
        "The employee's base compensation divided by the midpoint of their current pay band.",
      example: "75",
      key: "comparatio",
      operators: R.prop(DataType.Percent, DataTypeOperatorMap),
      title: "Compensation Ratio",
      type: DataType.Percent,
      display: { width: ColumnWidth.Medium },
      tickFormat: R.prop(DataType.Percent, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Percent, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    country: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      defaultValue: "US",
      description: "The employee's country of employment.",
      enumeration: toCountryEnumerations(CountryData),
      example: "New Zealand",
      key: "zone.country_details.nicename",
      operators: R.prop(DataType.String, EnumerationDataTypeOperatorMap),
      title: "Country",
      type: DataType.String,
      displayLabels: true,
      display: { width: ColumnWidth.Medium },
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    currency: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      defaultValue: "USD",
      description: "An employee's currency code.",
      enumeration: toCurrencyEnumerations(currencyCodes),
      example: "JPY",
      key: "currency",
      operators: R.prop(DataType.String, EnumerationDataTypeOperatorMap),
      title: "Currency",
      type: DataType.String,
      display: { width: ColumnWidth.Medium },
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    department: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "The employee's department",
      defaultOperator: Operator.Includes,
      example: "sales",
      key: "department",
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      title: "Department",
      type: DataType.String,
      display: { width: ColumnWidth.Medium },
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    department_id: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "The employee's department ID",
      defaultOperator: Operator.Includes,
      example: "0700",
      key: "department_id",
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      title: "Department ID",
      type: DataType.String,
      display: { width: ColumnWidth.Medium },
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    direct_reports: {
      aggregations: R.prop(DataType.NaturalNumber, DataTypeAggregationTypeMap),
      description: "The number of direct reports the employee has.",
      example: "7",
      key: "metrics.direct_reports",
      operators: R.prop(DataType.NaturalNumber, DataTypeOperatorMap),
      title: "Direct Reports",
      type: DataType.NaturalNumber,
      display: { width: ColumnWidth.Medium },
      tickFormat: R.prop(DataType.NaturalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.NaturalNumber, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    effective_from: {
      aggregations: R.prop(DataType.DateTime, DataTypeAggregationTypeMap),
      description:
        "Beginning of date range where this data is valid (for historical queries).",
      example: "07/21/2019",
      key: "effective_from",
      operators: R.prop(DataType.DateTime, DataTypeOperatorMap),
      title: "Effective From",
      type: DataType.DateTime,
      selectable: false,
      queryable: false,
      hidden: true,
    },
    effective_to: {
      aggregations: R.prop(DataType.DateTime, DataTypeAggregationTypeMap),
      description:
        "End of date range where this data is valid (for historical queries).",
      example: "07/21/2019",
      key: "effective_to",
      operators: R.prop(DataType.DateTime, DataTypeOperatorMap),
      title: "Effective To",
      type: DataType.DateTime,
      selectable: false,
      queryable: false,
      hidden: true,
    },
    employee_id: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "An employee's unique internal id.",
      defaultOperator: Operator.Includes,
      example: "ENG0002",
      key: "employee_id",
      hidden: true,
      queryable: false,
      selectable: false,
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      title: "Employee ID",
      type: DataType.String,
      display: { width: ColumnWidth.Small },
    },
    exemption_status: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "The employee's exemption status.",
      example: "exempt",
      key: "exemption_status",
      displayLabels: true,
      enumeration: [
        {
          value: "exempt",
          label: "Exempt",
        },
        {
          value: "non-exempt",
          label: "Non-Exempt",
        },
      ],
      operators: R.prop(DataType.String, EnumerationDataTypeOperatorMap),
      title: "Exemption Status",
      defaultValue: "exempt",
      type: DataType.String,
      display: { width: ColumnWidth.Medium },
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    external_benchmark_25: {
      aggregations: [AggregationType.Count, AggregationType.DistinctCount],
      dependencies: ["currency"],
      description:
        "25th percentile of the employee's most recent external benchmark.",
      example: "125,000",
      key: "benchmark.pct_25",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "External Benchmark (25th pctl)",
      type: DataType.Currency,
      display: { width: ColumnWidth.Standard },
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
      queryable: false,
    },
    external_benchmark_50: {
      aggregations: [AggregationType.Count, AggregationType.DistinctCount],
      dependencies: ["currency"],
      description:
        "50th percentile of the employee's most recent external benchmark.",
      example: "125,000",
      key: "benchmark.pct_50",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "External Benchmark (50th pctl)",
      type: DataType.Currency,
      display: { width: ColumnWidth.Standard },
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
      queryable: false,
    },
    external_benchmark_75: {
      aggregations: [AggregationType.Count, AggregationType.DistinctCount],
      dependencies: ["currency"],
      description:
        "75th percentile of the employee's most recent external benchmark.",
      example: "125,000",
      key: "benchmark.pct_75",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "External Benchmark (75th pctl)",
      type: DataType.Currency,
      display: { width: ColumnWidth.Standard },
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
      queryable: false,
    },

    external_benchmark_group: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description:
        "Bucket that the employee falls into based on their pay relative to the most recent external benchmark.",
      example: "< 25th Percentile",
      key: "external_benchmark_group",
      displayLabels: true,
      enumeration: [
        {
          value: null,
          label: "No Benchmark",
        },
        {
          value: BenchmarkGroup.LessThan25,
          label: "< 25th Percentile",
        },
        {
          value: BenchmarkGroup.From25To49,
          label: "25th-49th Percentile",
        },
        {
          value: BenchmarkGroup.From50To74,
          label: "50th-74th Percentile",
        },
        {
          value: BenchmarkGroup.GreaterThanOrEqualTo75,
          label: ">= 75th Percentile",
        },
      ],
      operators: R.prop(DataType.String, EnumerationDataTypeOperatorMap),
      title: "External Benchmark Group",
      defaultValue: BenchmarkGroup.LessThan25,
      type: DataType.String,
      display: { width: ColumnWidth.Medium },
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },

    external_benchmark_percentile: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "External Benchmark",
      example: "50th Percentile",
      key: "external_benchmark_percentile",
      displayLabels: true,
      enumeration: [
        {
          value: 1.0,
          label: "25th Percentile",
        },
        {
          value: 2.0,
          label: "50th Percentile",
        },
        {
          value: 3.0,
          label: "75th Percentile",
        },
      ],
      operators: R.prop(
        DataType.RationalNumber,
        EnumerationDataTypeOperatorMap
      ),
      title: "External Benchmark",
      defaultValue: 1.0,
      hidden: true,
      selectable: false,
      type: DataType.RationalNumber,
      display: { width: ColumnWidth.Medium },
    },
    gender: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "The employee's gender.",
      example: "female",
      key: "gender",
      displayLabels: true,
      enumeration: [
        {
          value: Gender.Female,
          label: "Female",
        },
        {
          value: Gender.Male,
          label: "Male",
        },
        {
          value: Gender.NotSpecified,
          label: "Not Specified",
        },
        {
          value: Gender.Other,
          label: "Other",
        },
      ],
      operators: R.prop(DataType.String, EnumerationDataTypeOperatorMap),
      title: "Gender",
      defaultValue: Gender.Male,
      defaultOperator: Operator.Equals,
      type: DataType.String,
      display: { width: ColumnWidth.Medium },
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    join_date: {
      aggregations: R.prop(DataType.DateTime, DataTypeAggregationTypeMap),
      description: "The date an the employee was hired.",
      example: "07/21/2019",
      key: "join_date",
      operators: R.prop(DataType.DateTime, DataTypeOperatorMap),
      title: "Hire Date",
      type: DataType.DateTime,
      display: { width: ColumnWidth.Medium },
    },
    internal_benchmark: {
      aggregations: [
        AggregationType.Average,
        AggregationType.Count,
        AggregationType.DistinctCount,
        AggregationType.Max,
        AggregationType.Min,
        AggregationType.Median,
      ],
      dependencies: ["internal_benchmark_peer_count"],
      description: "The employee's base compensation relative to their peers.",
      example: "2",
      key: "metrics.internal_benchmark",
      operators: R.prop(DataType.NaturalNumber, DataTypeOperatorMap),
      title: "Internal Benchmark",
      type: DataType.NaturalNumber,
      display: { width: ColumnWidth.Medium },
      tickFormat: R.prop(DataType.NaturalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.NaturalNumber, DataTypeValueFormatMap),
      hidden: true,
      queryable: false,
      selectable: false,
      measureFor: [
        GraphType.BarGraph,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    internal_benchmark_peer_count: {
      aggregations: [],
      description: "The number of employees benchmarked against.",
      example: "9",
      key: "metrics.internal_benchmark_peer_count",
      operators: R.prop(DataType.NaturalNumber, DataTypeOperatorMap),
      title: "Internal Benchmark Peer Count",
      type: DataType.NaturalNumber,
      selectable: false,
      queryable: false,
      display: { width: ColumnWidth.Medium },
    },
    internal_benchmark_percentile: {
      aggregations: [
        AggregationType.Average,
        AggregationType.Count,
        AggregationType.DistinctCount,
        AggregationType.Max,
        AggregationType.Min,
        AggregationType.Median,
      ],
      description:
        "Internal benchmark based on role and zone, expressed as a percentile.",
      example: "75",
      key: "metrics.internal_benchmark_percentile",
      operators: R.prop(DataType.Percent, DataTypeOperatorMap),
      title: "Internal Benchmark (pctl)",
      type: DataType.Percent,
      display: { width: ColumnWidth.Medium },
      tickFormat: R.prop(DataType.Percentile, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Percentile, DataTypeValueFormatMap),
    },
    job_family: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "The employee's current job family.",
      defaultOperator: Operator.Includes,
      example: "Marketing Leadership",
      key: "plan.job_family.name",
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      title: "Job Family",
      type: DataType.String,
      display: { width: ColumnWidth.Medium },
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    job_level: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "The employee's job level",
      defaultOperator: Operator.Includes,
      example: "IC1 - Junior",
      key: "plan.job_level.label",
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      title: "Job Level",
      type: DataType.String,
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    job_title: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "The employee's current job title.",
      defaultOperator: Operator.Includes,
      example: "Software Engineer",
      key: "plan.title",
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      title: "Job Title",
      type: DataType.String,
      display: { width: ColumnWidth.Medium },
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    job_title_remote_id: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "The plan ID from remote system.",
      defaultOperator: Operator.Includes,
      example: "1234",
      key: "plan.remote_id",
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      title: "Plan ID",
      type: DataType.String,
      display: { width: ColumnWidth.Medium },
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    last_promotion: {
      aggregations: R.prop(DataType.DateTime, DataTypeAggregationTypeMap),
      description: "The date the employee last had a compensation change.",
      example: "07/21/2019",
      key: "metrics.last_promotion",
      operators: R.prop(DataType.DateTime, DataTypeOperatorMap),
      title: "Last Comp Change",
      type: DataType.DateTime,
      display: { width: ColumnWidth.Medium },
    },
    last_promotion_months: {
      aggregations: [
        AggregationType.Average,
        AggregationType.Count,
        AggregationType.DistinctCount,
        AggregationType.Max,
        AggregationType.Min,
        AggregationType.Median,
      ],
      description: "Months since the employee last had a compensation change.",
      example: "14",
      key: "metrics.last_promotion_months",
      operators: R.prop(DataType.RationalNumber, DataTypeOperatorMap),
      title: "Last Comp Change (months)",
      type: DataType.RationalNumber,
      display: { width: ColumnWidth.Medium },
      tickFormat: R.prop(DataType.RationalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.RationalNumber, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    last_title_change: {
      aggregations: R.prop(DataType.DateTime, DataTypeAggregationTypeMap),
      description: "The date the employee last had a title change.",
      example: "07/21/2019",
      key: "metrics.last_title_change",
      operators: R.prop(DataType.DateTime, DataTypeOperatorMap),
      title: "Last Title Change",
      type: DataType.DateTime,
      display: { width: ColumnWidth.Medium },
    },
    last_title_change_months: {
      aggregations: [
        AggregationType.Average,
        AggregationType.Count,
        AggregationType.DistinctCount,
        AggregationType.Max,
        AggregationType.Min,
        AggregationType.Median,
      ],
      description: "Months since the employee last had a title change.",
      example: "14",
      key: "metrics.last_title_change_months",
      operators: R.prop(DataType.RationalNumber, DataTypeOperatorMap),
      title: "Last Title Change (months)",
      type: DataType.RationalNumber,
      display: { width: ColumnWidth.Medium },
      tickFormat: R.prop(DataType.RationalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.RationalNumber, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    location: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "The employee's city and locality.",
      defaultOperator: Operator.Includes,
      example: "City, State",
      key: "location",
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      title: "Location",
      type: DataType.String,
    },
    manager_name: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      defaultOperator: Operator.Includes,
      description: "The employee's manager's name.",
      display: { width: ColumnWidth.Medium },
      example: "Andrea Folsom",
      key: "manager.name",
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      queryable: false,
      selectable: false,
      title: "Manager's Name",
      type: DataType.String,
    },
    name: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      defaultOperator: Operator.Includes,
      description: "The employee's name.",
      display: { width: ColumnWidth.Standard },
      example: "Alex Anderson",
      key: "name",
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      title: "Employee's Name",
      type: DataType.String,
      required: true,
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    pay_band_max: {
      aggregations: R.prop(DataType.Currency, DataTypeAggregationTypeMap),
      dependencies: ["currency"],
      description:
        "The maximum compensation amount for the employee's current pay band.",
      example: "120,000",
      key: "plan.pay_band_max",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "Pay Band Max",
      type: DataType.Currency,
      display: { width: ColumnWidth.Medium },
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
    },
    pay_band_midpoint: {
      aggregations: R.prop(DataType.Currency, DataTypeAggregationTypeMap),
      dependencies: ["currency"],
      description:
        "The midpoint compensation amount for the employee's current pay band.",
      example: "120,000",
      key: "plan.pay_band_midpoint",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "Pay Band Midpoint",
      type: DataType.Currency,
      display: { width: ColumnWidth.Medium },
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
    },
    pay_band_min: {
      aggregations: R.prop(DataType.Currency, DataTypeAggregationTypeMap),
      dependencies: ["currency"],
      description:
        "The minimum compensation amount for the employee's current pay band.",
      example: "97,500",
      key: "plan.pay_band_min",
      operators: R.prop(DataType.Currency, DataTypeOperatorMap),
      title: "Pay Band Min",
      type: DataType.Currency,
      display: { width: ColumnWidth.Medium },
      tickFormat: R.prop(DataType.Currency, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.Currency, DataTypeValueFormatMap),
    },
    pay_rate: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "The employee's pay rate.",
      example: "Salary",
      key: "pay_rate",
      displayLabels: true,
      enumeration: [
        {
          value: "salary",
          label: "Salary",
        },
        {
          value: "hourly",
          label: "Hourly",
        },
        {
          value: "daily",
          label: "Daily",
        },
      ],
      operators: R.prop(DataType.String, EnumerationDataTypeOperatorMap),
      title: "Pay Rate",
      defaultValue: "salary",
      type: DataType.String,
      display: { width: ColumnWidth.Medium },
    },
    performance: {
      aggregations: R.prop(DataType.RationalNumber, DataTypeAggregationTypeMap),
      description: "Aggregate performance score for the employee",
      example: "3.5",
      key: "performance",
      operators: R.prop(DataType.RationalNumber, DataTypeOperatorMap),
      title: "Performance",
      type: DataType.RationalNumber,
      display: { width: ColumnWidth.Medium },
      tickFormat: R.prop(DataType.RationalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.RationalNumber, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    remote_id: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: "An employee's unique id from external system.",
      defaultOperator: Operator.Includes,
      example: "ENG0002",
      key: "remote_id",
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      title: "Employee ID",
      type: DataType.String,
      display: { width: ColumnWidth.Small },
    },
    id: {
      description: "The revision id of this employee.",
      example: "23",
      key: "id",
      queryable: false,
      selectable: false,
      title: "Revision",
      hidden: true,
      type: DataType.NaturalNumber,
    },
    status: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      defaultValue: EmployeeStatus.Active,
      description: "An employee's current status.",
      display: { width: ColumnWidth.Medium },
      enumeration: [
        {
          value: EmployeeStatus.Active,
          label: "Active",
        },
        {
          value: EmployeeStatus.Pending,
          label: "Pending",
        },
        {
          value: EmployeeStatus.Leave,
          label: "On Leave",
        },
        {
          value: EmployeeStatus.Inactive,
          label: "Inactive",
        },
      ],
      example: EmployeeStatus.Active,
      key: "status",
      operators: R.prop(DataType.String, EnumerationDataTypeOperatorMap),
      title: "Status",
      type: DataType.String,
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
    termination_date: {
      aggregations: R.prop(DataType.DateTime, DataTypeAggregationTypeMap),
      description: "The date an the employee was terminated.",
      example: "07/21/2019",
      key: "termination_date",
      operators: R.prop(DataType.DateTime, DataTypeOperatorMap),
      title: "Termination Date",
      type: DataType.DateTime,
      hidden: true,
      selectable: false,
      queryable: false,
    },
    tenure: {
      aggregations: R.prop(DataType.NaturalNumber, DataTypeAggregationTypeMap),
      description: "Number of months an employee has been with the company.",
      example: "24",
      display: { width: ColumnWidth.Medium },
      key: "metrics.tenure",
      operators: R.prop(DataType.NaturalNumber, DataTypeOperatorMap),
      title: "Tenure At Company (months)",
      type: DataType.NaturalNumber,
      tickFormat: R.prop(DataType.NaturalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.NaturalNumber, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    role_tenure: {
      aggregations: R.prop(DataType.NaturalNumber, DataTypeAggregationTypeMap),
      description:
        "Number of months an employee has been in their current role.",
      example: "24",
      key: "metrics.role_tenure",
      operators: R.prop(DataType.NaturalNumber, DataTypeOperatorMap),
      title: "Tenure In Role (months)",
      type: DataType.NaturalNumber,
      tickFormat: R.prop(DataType.NaturalNumber, DataTypeTickFormatMap),
      valueFormat: R.prop(DataType.NaturalNumber, DataTypeValueFormatMap),
      measureFor: [
        GraphType.BarGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.StackedBarGraph,
      ],
    },
    zone_label: {
      aggregations: R.prop(DataType.String, DataTypeAggregationTypeMap),
      description: `The employee's zone.`,
      example: "Zone 2",
      key: "zone.description",
      operators: R.prop(DataType.String, DataTypeOperatorMap),
      title: "Zone",
      type: DataType.String,
      dimensionFor: [
        GraphType.BarGraph,
        GraphType.LineGraph,
        GraphType.Metrics,
        GraphType.PieGraph,
        GraphType.ScatterPlot,
        GraphType.SegmentedTable,
        GraphType.StackedBarGraph,
      ],
    },
  },
  filters: {
    [Filter.Active]: [
      {
        field: "status",
        operator: Operator.Equals,
        value: EmployeeStatus.Active,
      },
    ],
  },
};
