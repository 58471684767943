export enum Gender {
  Female = "female",
  Male = "male",
  NotSpecified = "not-specified",
  Other = "other",
}

export const GenderLabel = {
  [Gender.Female]: "female",
  [Gender.Male]: "male",
  [Gender.NotSpecified]: "not specified",
  [Gender.Other]: "other",
};

export enum EmployeeStatus {
  /* Active, working and drawing a salary. */
  Active = "Active",
  /* Terminated. */
  Inactive = "Inactive",
  /* Not active, but no termination date. Assumed to be on leave. */
  Leave = "Leave",
  /* Active, but not yet started. */
  Pending = "Pending",
}

export enum ExemptionStatus {
  Exempt = "exempt",
  NonExempt = "non-exempt",
}

export enum PayRate {
  Salary = "salary",
  Hourly = "hourly",
  Daily = "daily",
}
