import * as R from "ramda";

export class BenchmarkDataPoint {
  pct_25: number;
  pct_50: number;
  pct_75: number;
}

export class Benchmark extends BenchmarkDataPoint {
  id: string;
  job_title_id: string;
  zone_id: string;
  survey_date: string;
  notes?: string;
  aging_factor?: number;
}

export class AgedBenchmark extends Benchmark {
  original_date: string;
  aging_details: BenchmarkDataPoint & { date: string }[];
}

export const isAgedBenchmark = (
  b: Benchmark | AgedBenchmark
): b is AgedBenchmark => {
  return !R.isNil(R.prop("original_date", b));
};

export enum BenchmarkGroup {
  LessThan25 = "<25",
  From25To49 = "25-49",
  From50To74 = "50-74",
  GreaterThanOrEqualTo75 = ">=75",
}

/**
 * Maps the buckets to a score so we can do numeric comparisons.
 */
export type BenchmarkScore = 0.5 | 1.0 | 1.5 | 2.0 | 2.5 | 3.0 | 3.5;

export const BenchmarkScoreMap: { [key in BenchmarkGroup]: BenchmarkScore } = {
  [BenchmarkGroup.LessThan25]: 0.5,
  [BenchmarkGroup.From25To49]: 1.5,
  [BenchmarkGroup.From50To74]: 2.5,
  [BenchmarkGroup.GreaterThanOrEqualTo75]: 3.5,
};
