import {
  AggregationType,
  Operator,
  DataType,
  FormatFunction,
} from "./constant";

export const DefaultOperatorMap = {
  [DataType.Boolean]: Operator.Equals,
  [DataType.Currency]: Operator.GreaterThan,
  [DataType.DateTime]: Operator.GreaterThan,
  [DataType.Identifier]: Operator.Equals,
  [DataType.NaturalNumber]: Operator.Equals,
  [DataType.Percent]: Operator.GreaterThan,
  [DataType.Percentile]: Operator.GreaterThan,
  [DataType.RationalNumber]: Operator.GreaterThan,
  [DataType.String]: Operator.Includes,
  [DataType.WholeNumber]: Operator.Equals,
};

export const EnumerationDataTypeOperatorMap = {
  [DataType.String]: [Operator.Equals, Operator.NotEquals],
  [DataType.Identifier]: [Operator.Equals, Operator.NotEquals],
  [DataType.WholeNumber]: [
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotEquals,
  ],
  [DataType.NaturalNumber]: [
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotEquals,
  ],
  [DataType.RationalNumber]: [
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotEquals,
  ],
  [DataType.Percent]: [
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotEquals,
  ],
  [DataType.Percentile]: [
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotEquals,
  ],
};

export const DataTypeAggregationTypeMap = {
  [DataType.Boolean]: [AggregationType.Count, AggregationType.DistinctCount],
  [DataType.Currency]: [
    AggregationType.Count,
    AggregationType.DistinctCount,
    /**
     * Additional aggregations only make sense for homogenous currencies. If
     * this is the case you can use the aggregation list for RationalNumber's.
     */
  ],
  [DataType.Identifier]: [AggregationType.Count, AggregationType.DistinctCount],
  [DataType.DateTime]: [
    AggregationType.Count,
    AggregationType.DistinctCount,
    AggregationType.Min,
    AggregationType.Max,
  ],
  [DataType.WholeNumber]: [
    AggregationType.Count,
    AggregationType.DistinctCount,
    AggregationType.Min,
    AggregationType.Max,
    AggregationType.Median,
    AggregationType.Sum,
  ],
  [DataType.NaturalNumber]: [
    AggregationType.Average,
    AggregationType.Count,
    AggregationType.DistinctCount,
    AggregationType.Min,
    AggregationType.Max,
    AggregationType.Median,
    AggregationType.Sum,
  ],
  [DataType.Percent]: [
    AggregationType.Average,
    AggregationType.Count,
    AggregationType.DistinctCount,
    AggregationType.Min,
    AggregationType.Max,
    AggregationType.Median,
  ],
  [DataType.Percentile]: [
    AggregationType.Average,
    AggregationType.Count,
    AggregationType.DistinctCount,
    AggregationType.Min,
    AggregationType.Max,
    AggregationType.Median,
    AggregationType.Sum,
  ],
  [DataType.RationalNumber]: [
    AggregationType.Average,
    AggregationType.Count,
    AggregationType.DistinctCount,
    AggregationType.Min,
    AggregationType.Max,
    AggregationType.Median,
    AggregationType.Sum,
  ],
  [DataType.String]: [AggregationType.Count, AggregationType.DistinctCount],
};

export const DataTypeOperatorMap = {
  [DataType.Boolean]: [Operator.Equals, Operator.NotEquals],
  [DataType.Identifier]: [Operator.Equals, Operator.NotEquals],
  [DataType.DateTime]: [
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.Equals,
    Operator.NotEquals,
  ],
  [DataType.WholeNumber]: [
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.Equals,
    Operator.NotEquals,
  ],
  [DataType.NaturalNumber]: [
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.Equals,
    Operator.NotEquals,
  ],
  [DataType.RationalNumber]: [
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.Equals,
    Operator.NotEquals,
  ],
  [DataType.Percent]: [
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.Equals,
    Operator.NotEquals,
  ],
  [DataType.Percentile]: [
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.Equals,
    Operator.NotEquals,
  ],
  [DataType.Currency]: [
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.Equals,
    Operator.NotEquals,
  ],
  [DataType.String]: [
    // We effectively treat equals as includes in elastic-based searches so removing these.
    // Operator.Equals,
    // Operator.NotEquals,
    Operator.Includes,
    Operator.Excludes,
  ],
};

// TODO: Consolidate on this operator map. Currently backend reporting does not support Between ranges.
export const ClientDataTypeOperatorMap = {
  [DataType.Boolean]: [Operator.Equals, Operator.NotEquals],
  [DataType.Identifier]: [Operator.Equals, Operator.NotEquals],
  [DataType.DateTime]: [
    Operator.Between,
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotBetween,
    Operator.NotEquals,
  ],
  [DataType.WholeNumber]: [
    Operator.Between,
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotBetween,
    Operator.NotEquals,
  ],
  [DataType.NaturalNumber]: [
    Operator.Between,
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotBetween,
    Operator.NotEquals,
  ],
  [DataType.RationalNumber]: [
    Operator.Between,
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotBetween,
    Operator.NotEquals,
  ],
  [DataType.Percent]: [
    Operator.Between,
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotBetween,
    Operator.NotEquals,
  ],
  [DataType.Percentile]: [
    Operator.Between,
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotBetween,
    Operator.NotEquals,
  ],
  [DataType.Currency]: [
    Operator.Between,
    Operator.Equals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEqualTo,
    Operator.LessThan,
    Operator.LessThanOrEqualTo,
    Operator.NotBetween,
    Operator.NotEquals,
  ],
  [DataType.String]: [
    Operator.Equals,
    Operator.Excludes,
    Operator.Includes,
    Operator.NotEquals,
  ],
};

export const DataTypeTickFormatMap = {
  [DataType.Currency]: FormatFunction.CurrencySI,
  [DataType.NaturalNumber]: FormatFunction.WholeNumber,
  [DataType.WholeNumber]: FormatFunction.WholeNumber,
  [DataType.Percent]: FormatFunction.Percent,
  [DataType.Percentile]: FormatFunction.Percent,
  [DataType.RationalNumber]: FormatFunction.Decimal,
};

export const DataTypeValueFormatMap = {
  [DataType.Currency]: FormatFunction.CurrencyRounded,
  [DataType.NaturalNumber]: FormatFunction.WholeNumber,
  [DataType.WholeNumber]: FormatFunction.WholeNumber,
  [DataType.Percent]: FormatFunction.PercentDecimal,
  [DataType.Percentile]: FormatFunction.Percent,
  [DataType.RationalNumber]: FormatFunction.Decimal,
};
