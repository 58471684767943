export const ColumnWidth = {
  Compact: 50,
  Large: 250,
  ExtraLarge: 300,
  Maximum: 400,
  Medium: 150,
  Narrow: 30,
  Small: 125,
  Standard: 200,
};
