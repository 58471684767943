import { countries } from "country-data";
import { Country } from "../../data/country/type";
import * as R from "ramda";

export const CountryData = R.filter(
  R.whereEq({ status: "assigned" }),
  countries.all
);

export const getCurrencyCode = (country: string): string => {
  const data = R.find(R.whereEq({ alpha2: country }), CountryData);
  return R.head(data.currencies);
};

export function countryToOption(country: Country) {
  return {
    label: country.country_label,
    value: country.country,
  };
}
