import * as R from "ramda";
import {
  AggregationType,
  Operator,
  GraphType,
  DataType,
} from "@milio/lib/data/report/constant";
import { Schema as EmployeeSchema } from "@milio/lib/schema/employee/schema";
import { Schema as PlanSchema } from "@milio/lib/schema/plan/schema";
import { ReportType } from ".generated/models";

export const EXPORT_LEGEND_LIMIT = 10;
export const APP_LEGEND_LIMIT = 20;

export const AggregationTypeLabels = {
  [DataType.DateTime]: {
    [AggregationType.Max]: "Latest",
    [AggregationType.Min]: "Earliest",
  },
  default: {
    [AggregationType.Average]: "Average",
    [AggregationType.Count]: "Count",
    [AggregationType.DistinctCount]: "Distinct Count",
    [AggregationType.Max]: "Max",
    [AggregationType.Median]: "Median",
    [AggregationType.Min]: "Min",
    [AggregationType.Sum]: "Sum",
  },
};

export const OperatorLabels = {
  [DataType.DateTime]: {
    [Operator.Equals]: "On",
    [Operator.GreaterThanOrEqualTo]: "After or On",
    [Operator.GreaterThan]: "After",
    [Operator.LessThanOrEqualTo]: "Before or On",
    [Operator.LessThan]: "Before",
    [Operator.NotEquals]: "Not On",
  },
  default: {
    [Operator.Between]: "Between",
    [Operator.Contains]: "Has",
    [Operator.DoesNotContain]: "Does Not Have",
    [Operator.Equals]: "Equals",
    [Operator.Excludes]: "Does not Contain",
    [Operator.GreaterThanOrEqualTo]: "Greater Than or Equal to",
    [Operator.GreaterThan]: "Greater Than",
    [Operator.Includes]: "Contains",
    [Operator.LessThanOrEqualTo]: "Less Than or Equal to",
    [Operator.LessThan]: "Less Than",
    [Operator.NotBetween]: "Not Between",
    [Operator.NotEquals]: "Not Equal to",
  },
};

export enum DisabledReason {
  FieldNotAvailable,
  FieldLimitReached,
}

export const DisabledReasons = {
  [DisabledReason.FieldNotAvailable]:
    "Include the field in the report table to use in the graph.",
  [DisabledReason.FieldLimitReached]:
    "No more fields can be selected for this graph type.",
};

export const SchemaByReportType = {
  [ReportType.Employee]: EmployeeSchema,
  [ReportType.Plan]: PlanSchema,
};

export const GraphTypeOptions: {
  label: string;
  value: GraphType;
  reports: ReportType[];
}[] = [
  {
    label: "Bar",
    value: GraphType.BarGraph,
    reports: [ReportType.Plan, ReportType.Employee],
  },
  {
    label: "Pie",
    value: GraphType.PieGraph,
    reports: [ReportType.Plan, ReportType.Employee],
  },
  // Removed until history is fast
  //   {
  //     label: "Line",
  //     value: GraphType.LineGraph,
  //     reports: [ReportType.Employee, ReportType.Plan],
  //   },
  {
    label: "Payband",
    value: GraphType.PaybandAdheranceGraph,
    reports: [ReportType.Employee],
  },
  {
    label: "Scatter",
    value: GraphType.ScatterPlot,
    reports: [ReportType.Employee, ReportType.Plan],
  },
  {
    label: "Stacked Bar",
    value: GraphType.StackedBarGraph,
    reports: [ReportType.Employee, ReportType.Plan],
  },
];

export const AggregationMethodMap = {
  [AggregationType.Count]: R.length,
  [AggregationType.Average]: R.mean,
  [AggregationType.Median]: R.median,
  [AggregationType.Sum]: R.sum,
  [AggregationType.Max]: R.reduce(R.max, -Infinity),
  [AggregationType.Min]: R.reduce(R.min, Infinity),
};

interface GraphDetails {
  limit: number;
  dimensionMax: number;
  dimensionMin?: number;
  measureMax: number;
  measureMin?: number;
  additionalMeasures?: string[];
}

export const DefaultGraphDetails: Record<GraphType, GraphDetails> = {
  [GraphType.BarGraph]: {
    dimensionMax: 1,
    dimensionMin: 1,
    measureMin: 1,
    measureMax: 2,
    limit: 25,
    additionalMeasures: ["count"],
  },
  [GraphType.Metrics]: {
    dimensionMax: 1,
    dimensionMin: 0,
    measureMin: 1,
    measureMax: 20,
    limit: Infinity,
  },
  [GraphType.LineGraph]: {
    dimensionMax: 1,
    dimensionMin: 1,
    measureMax: 1,
    measureMin: 1,
    limit: 15,
    additionalMeasures: ["count"],
  },
  [GraphType.PaybandAdheranceGraph]: {
    dimensionMax: 0,
    measureMax: 0,
    limit: 10,
  },
  [GraphType.PieGraph]: {
    dimensionMax: 1,
    dimensionMin: 1,
    measureMax: 1,
    measureMin: 1,
    limit: 15,
    additionalMeasures: ["count"],
  },
  [GraphType.ScatterPlot]: {
    dimensionMax: 2,
    dimensionMin: 1,
    measureMax: 2,
    measureMin: 1,
    limit: 1000,
    additionalMeasures: ["count"],
  },
  [GraphType.SegmentedTable]: {
    dimensionMax: 10,
    dimensionMin: 1,
    measureMax: 1,
    measureMin: 1,
    limit: 1000,
    additionalMeasures: ["count"],
  },
  [GraphType.StackedBarGraph]: {
    dimensionMax: 2,
    dimensionMin: 2,
    measureMin: 1,
    measureMax: 1,
    limit: 25,
    additionalMeasures: ["count"],
  },
};

export const GraphDetails: Record<
  ReportType,
  Record<GraphType, GraphDetails>
> = {
  [ReportType.Plan]: {
    ...DefaultGraphDetails,
    [GraphType.LineGraph]: R.omit(
      ["additionalMeasures"],
      DefaultGraphDetails[GraphType.LineGraph]
    ),
  },
  [ReportType.Employee]: { ...DefaultGraphDetails },
};
